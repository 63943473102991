
import axios from 'axios'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    faturas: [],
    pagamentos: [],
    // creditos: [],
    filtroPagar: true,
    filtroPagas: false,
    items: [],
    dataInicio: moment().startOf("month").format("YYYY-MM-DD"),
    dataFinal: moment().endOf("month").format("YYYY-MM-DD"),
    filtroPagamentos: false,
    total_receber: 0,
    total_atrasados : 0,
    totalPagamentos: 0,
    cliente_token: null,
    cliente_id: 0
  },
  mutations: {
    STORE_FATURAS(state, result) {
      state.faturas = result
    },

    STORE_FATURAS_PAGAMENTOS(state, result) {
      state.pagamentos = result
    },

    UPDATE_FATURAS(state, result) {
      state.faturas.splice(result.index, 1, result.dados)
    },

    STORE_PAGAR(state, result) {
      state.filtroPagar = result
    },

    STORE_PAGAS(state, result) {
      state.filtroPagas = result
    },

    STORE_TOTAL_RECEBER(state, payload) {
      state.total_receber = payload
    },

    STORE_ATRASADOS(state, payload) {
      state.total_atrasados = payload
    },
    STORE_TOTAL_PAGAMENTOS(state, payload) {
      state.totalPagamentos = payload
    },

    STORE_PAGAMENTOS(state, payload) {
      state.filtroPagamentos = payload
    },

    STORE_CLIENTE_TOKEN(state, payload) {
      state.cliente_token = payload
    },

    STORE_CLIENTE_ID(state, payload) {
      state.cliente_id = payload
    },

    SET_DATAS(state, payload) {
      state.dataInicio = payload.dataInicio;
      state.dataFinal = payload.dataFinal;
    },

    STORE_ITEMS(state, payload) {
      if (payload && payload.length > 0) {
        state.items[payload[0].receita_id] = [...payload];
        state.items.push();
      }
    },
  },
  actions: {
    listaFaturas(context, filters) {
      axios(`odontodocs/financeiro/${filters.laboratorioSelecionado}?data_inicio=${context.state.dataInicio}&data_final=${context.state.dataFinal}`).then((res) => {
        context.commit('STORE_FATURAS', res.data.parcelas)
        context.commit('STORE_FATURAS_PAGAMENTOS', res.data.pagamentos)
        context.commit('STORE_TOTAL_RECEBER', res.data.total_receber)
        context.commit('STORE_ATRASADOS', res.data.total_atrasados)
        context.commit('STORE_TOTAL_PAGAMENTOS', res.data.total_pagamentos)
        context.commit('STORE_CLIENTE_TOKEN', res.data.cliente_token)
        context.commit('STORE_CLIENTE_ID', res.data.cliente_id)
      })
    },
    listaItems(context, id) {
      axios('odontodocs/financeiro/items/' + id).then(res => {
        context.commit("STORE_ITEMS", res.data);
      })
    },
  },

}

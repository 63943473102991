import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import laboratorios from './laboratorios'
import pedidos from './pedidos'
import tabelas from './tabelas'
import financeiro from './financeiro'
import dashboard from './dashboard'
import chat from './chat'

const ConfigVuePersitedState = createPersistedState({
  paths: ['user']
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    user,
    laboratorios,
    pedidos,
    tabelas,
    financeiro,
    dashboard,
    chat,
  },
  strict: process.env.DEV,
  plugins: [ConfigVuePersitedState]
})

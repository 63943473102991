
import Guard from './../services/auth'

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: {
      pageTitle: 'Home',
      owner: false,
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
]
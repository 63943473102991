import axios from 'axios'

export default {
    namespaced: true,
    state: {
        dashboard: [],
    },
    mutations: {
        STORE_DASHBOARD(state, result) {
            state.dashboard = result
        }
    },
    actions: {
        listaDashboard(context, laboratorio_id) {
            axios(`odontodocs/dashboard/${laboratorio_id}`).then( (res) => {
                context.commit('STORE_DASHBOARD', res.data)
            })
        }
    }
}

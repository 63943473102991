import axios from 'axios'

export default  {
    namespaced: true,
    state: {
        tabelas: [],
        clienteConfig: {}
    },
    mutations: {
        STORE_TABELAS(state, result) {
            state.tabelas = result
        },
        STORE_CLIENTE_CONFIG(state, result) {
            state.clienteConfig = result
        },
    },
    actions: {
        listaTabelas(context, laboSelecionado) {
            axios("odontodocs/tabelas/" + laboSelecionado).then((res) => {
                context.commit('STORE_TABELAS', res.data.categorias)
                context.commit('STORE_CLIENTE_CONFIG', res.data.cliente)
            });
        }
    }

}

import axios from 'axios'
import store from '@/store'

export default {
    namespaced: true,

    state: {
        listaServicosChat: [],
        listaFiltrada: [],
        selecionado: '',
        arraySelecionado: [],
        mensagens: [],
        campoBuscar: false
    },

    mutations: {

        // LISTA SERVIÇOS
        STORE_LISTA_SERVICOS(state, result) {
            state.listaServicosChat = result
        },
        ADD_LISTA_SERVICOS(state, result) {
            state.listaServicosChat.unshift(result)
        },
        SETAR_LISTA_SEVICOS_LIDA(state, os_item_id) {
            let index = state.listaServicosChat.findIndex( v => v.os_item_id === os_item_id)
            if(index >= 0) {
                state.listaServicosChat[index].nova_mensagem = false
            }
        },

        // MENSAGENS
        STORE_MENSAGENS(state, result) {
            state.mensagens = result
        },
        SET_MENSAGENS(state, result) {
            if(result.mensagem.para_docs === 1) {
                let laboratorio = store.state.laboratorios.laboratorios.find( v => v.id === result.item.id_company).nome
                let existeItem = state.listaServicosChat.find( v => v.os_item_id === result.item.os_item_id)
                if(existeItem === undefined) {
                    result.item.laboratorio = laboratorio
                    state.listaServicosChat.unshift(result.item )
                }else{
                    let index = state.listaServicosChat.indexOf(existeItem)
                    existeItem.nova_mensagem = true
                    existeItem.tem_mensagem = true
                    existeItem.laboratorio = laboratorio
                    state.listaServicosChat.splice(index, 1, existeItem)
                }
            }

            if(state.arraySelecionado && state.arraySelecionado.os_item_id === result.mensagem.os_item_id) {
                state.mensagens.push(result.mensagem)
                store.commit('chat/SETAR_LISTA_SEVICOS_LIDA', result.mensagem.os_item_id)
                let payload = {
                    os_item_id: result.mensagem.os_item_id,
                    id_company: result.mensagem.id_company
                }
                store.dispatch('chat/setaMensagemAbertaLida', payload)
            }
        },

        ADD_MENSAGENS(state, result) {
            state.mensagens.push(result.mensagem)
        },

        // LISTA FILTRADA

        STORE_LISTA_FILTRADA(state, result) {
            state.listaFiltrada = result
        },

        STORE_CAMPO_BUSCAR(state, result) {
            state.campoBuscar = result
        },

        // SELECIONADOS
        STORE_SELECIONADO(state, result) {
            state.selecionado = result
        },

        STORE_ARRAY_SELECIONADO(state, result) {
            state.arraySelecionado = result
        },

    },

    getters: {
        nomeServicoSelecionado: state => {
            let nome = state.listaServicosChat.find( v => v.os_item_id === state.selecionado)

            if(nome != undefined) {
                return nome.servico_nome
            }

            return ''
        },
        listaFiltros: state => {
            return state.listaFiltrada.length || state.campoBuscar ? state.listaFiltrada : state.listaServicosChat
        },
        countNovasMensagens: state => {
            let count = 0
            if(state.listaServicosChat.length) {
                count = state.listaServicosChat.reduce( (a, val ) => {
                    if(val.nova_mensagem) {
                        return a + 1
                    }
                    return a
                }, 0)
            }
            return count
        }
    },

    actions: {
        listaServicosChat(context, id_company) {
            axios('odontodocs/chat/'+id_company).then( (res) => {
                context.commit('STORE_LISTA_SERVICOS', res.data)
            })
        },
        listaMensagens(context, payload) {
            axios(`odontodocs/chat/${payload.id_company}/${payload.os_item_id}`).then( (res) => {
                context.commit('STORE_MENSAGENS', res.data)
            })
        },
        setaMensagemAbertaLida(context, payload) {
            axios.post(`odontodocs/chat/${payload.os_item_id}/${payload.id_company}`).then( () => {})
        }
    }


}

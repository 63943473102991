
import Guard from './../services/auth'

export default [
    {
        path: '/redefinir-senha', name: 'reset-password',
        component: () => import('@/views/auth/ResetPassword'),
        meta: {layout: 'full'}
    },

    {
        path: '/esqueceu-senha', name: 'forgot-password',
        component: () => import('@/views/auth/ForgotPassword.vue'),
        meta: {layout: 'full'}
    },

    {
        path: '/login', name: 'login',
        component: () => import('@/views/auth/Login.vue'),
        beforeEnter: Guard.redirectIfAuthenticated,
        meta: {layout: 'full'}
    },

    {
        path: '/logout', name: 'logout',
        component: () => import('@/views/auth/Logout.vue'),
        meta: {layout: 'full'}
    },

]

import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import responseMessages from './services/responseMessages'
import getDate from './services/data'
import money from "v-money"
import VueHtmlToPaper from 'vue-html-to-paper';
import baseUrl from "@/services/baseUrl";
import Cookie from "js-cookie";

Vue.use(money, { precision: 2 })
Vue.use(VueHtmlToPaper)

let bearerToken = Cookie.get('docs_token')
import router from './router'
import store from './store'
import App from './App.vue'


import Echo from "laravel-echo";

window.io = require('socket.io-client')

/*
let optionsEcho = {
  broadcaster: 'socket.io',
  host: `${baseUrl}:${8443}`,
  auth: {
    headers: {
      Authorization: 'Bearer ' + bearerToken,
    }
  }
}

window.Echo = new Echo(optionsEcho)

require('./echo-socket-io')
*/

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select'
import '@/libs/sweet-alerts'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.prototype.$getDate = getDate
Vue.prototype.$responseMessages = responseMessages

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

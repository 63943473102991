import store from "@/store";


function events(store) {
    const alerta = () => {
        var playPromise = audio.play();
        if (playPromise !== undefined) {
            playPromise.then(_ => { })
                .catch(error => { });
        }
    };


    const vuexLocal =  localStorage.getItem('vuex')
    const odontoUserId = vuexLocal !== null ? JSON.parse(vuexLocal).user.user.id : ''

    console.log('odontodocs id_Company', odontoUserId)
    window.Echo.channel(`smartprotese_database_private-chat-mensagem-odontodocs.${odontoUserId}`)
        .listen('MensagemChatNovaOdontodocs', (e) =>{
            console.log('MensagemChatNovaOdontodocs', e)
            if(e.mensagem.mensagem.para_docs) {
                store.commit('chat/SET_MENSAGENS', e.mensagem)
                alerta()
            }
        })

}

export default events


export default {

  getMessage(error, message){

       if(error){

           if(error?.response?.status == 422){
               return {
                   hasMessage: true,
                   message: '',
                   messageArray: error.response.data.errors,
                   color: 'danger'
               }

           }

           else if(error?.response?.status == 401){

               return {
                   hasMessage: true,
                   message: error.response.data.message,
                   messageArray: '',
                   color: 'danger'
               }

           }

           else if(error?.response?.status == 500){

             let message = 'Ops, Algo deu Errado, tente novamente!'

             if(error?.response?.data.hasOwnProperty('message')){
               message = error.response.data.message
             }

             if(error?.response?.data.hasOwnProperty('codigo')){
               message = error.response.data.message
             }

               return {
                   hasMessage: true,
                   message: message,
                   messageArray: '',
                   color: 'danger'
               }

           }
           else{

               return {
                   hasMessage: true,
                   message: 'Ops, Algo deu Errado, tente novamente!',
                   messageArray: '',
                   color: 'danger'
               }

           }

       }
       else{

           return {
               hasMessage: true,
               message: message,
               messageArray: '',
               color: 'success'
           }

       }


  }






}
